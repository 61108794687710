import React, { useEffect, useState } from "react";
import { multimodal } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { useParams } from "react-router-dom";
import axios from "axios";


const BlogDetail = () => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    
      function getData() {
        axios
          .get("https://www.tradegroup.az/tradegroup/blogs.php", { withCredentials: false })
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.error("Veri alınırken hata oluştu:", error);
          });
      }
    
      useEffect(() => {
        getData()
      }, []);


  const { id } = useParams();
  const blog = data.find(blog => blog.id == parseInt(id));

  if (!blog) {
    return <div className="text-center text-red-500 font-bold">Blog tapılmadı!</div>;
  }
  
  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row mt-5">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
             {blog.title}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
          {blog.content}
          </p>
        </div>
        <div className="w-full md:w-5/12 p-5 md:p-10">
          <img src={`https://www.tradegroup.az/tradegroup/${blog.image}`}></img>
        </div>
        <div className='w-full md:w-1/12 bg-white'>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
