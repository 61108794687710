import React, { useEffect, useRef, useState } from 'react'
import { Anbar, azflag, Demir, Deniz, engflag, flag, Gomruk, Hava, KompleksLogistika, logo, Multimodal, Quru, rusflag } from '../../assets/index'
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import { useLocation } from 'react-router-dom';


const languageOptions = [
    { value: "en", label: "English", flag: engflag }, 
    { value: "az", label: "Azərbaycan", flag: azflag },
    { value: "rus", label: "Russian", flag: rusflag },
  ];
  
  i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        az: { translation: azTranslation },
        rus: { translation: rusTranslation },
    },
    lng: localStorage.getItem("language") || "az",
    fallbackLng: "az",
    interpolation: { escapeValue: false },
  });

const CommonHeader = () => {

  let params = useLocation()
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));
    const [bgImage, setBgImage] = useState(Quru)
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isServicesExpanded, setIsServicesExpanded] = useState(false);
    const [firstText, setFirstText] = useState('')
    const [secondText, setSecondText] = useState('')
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const productsTimeoutRef = useRef(null);
    const servicesTimeoutRef = useRef(null);

  
    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage) {
            const language = languageOptions.find(lang => lang.value === savedLanguage);
            if (language) {
                setSelectedLanguage(language);
                i18n.changeLanguage(language.value);
            }
        }
    }, []);
  
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
  
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        i18n.changeLanguage(language.value);
        localStorage.setItem("language", language.value);
        if(params.pathname === '/about') {
          setFirstText('')
          setSecondText(t("Haqqımızda"))
        
          }
          else if(params.pathname === '/') {
            setFirstText('')
            setSecondText(t("Əsas"))
            }
          else if(params.pathname === '/contact') {
            setFirstText('')
            setSecondText(t("Əlaqə"))
            }
            else if(params.pathname === '/blogs') {
              setFirstText('')
              setSecondText(t("Bloqlar"))
              }
            else if(params.pathname === '/xidmetler') {
              setFirstText('')
            setSecondText(t("Xidmətlərimiz"))
              }
          else if(params.pathname === '/quruyol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Quru yolu ilə yük daşımaları"))
            setBgImage(Quru)
          }
          else if(params.pathname === '/havayol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
            setBgImage(Hava)
          }
          else if(params.pathname === '/demiryol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
            setBgImage(Demir)
          }
          else if(params.pathname === '/denizyol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
            setBgImage(Deniz)
          }
          else if(params.pathname === '/anbar-xidmeti') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Anbar xidməti"))
            setBgImage(Anbar)
          }
          else if(params.pathname === '/multimodal') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Multimodal daşımalar"))
            setBgImage(Multimodal)
          }
          else if(params.pathname === '/kompleks-logistika') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Kompleks logistika"))
            setBgImage(KompleksLogistika)
          }
          else if(params.pathname === '/gomruk-resmi') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Gömrük rəsmiləşdirilməsi"))
            setBgImage(Gomruk)
          }
          else if(params.pathname === '/topdan-satis') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Topdan satış"))
          }
    };
  
    
    const togglePanel = () => {
      setIsPanelOpen(!isPanelOpen);
    };
  
    const toggleServices = () => {
      setIsServicesExpanded(!isServicesExpanded);
    };
  
  
  useEffect(() => {
    if(params.pathname === '/about') {
    setFirstText('')
    setSecondText(t("Haqqımızda"))
  
    }
    else if(params.pathname === '/') {
      setFirstText('')
      setSecondText(t("Əsas"))
      }
      else if(params.pathname === '/xidmetler') {
        setFirstText('')
      setSecondText(t("Xidmətlərimiz"))
        }
    else if(params.pathname === '/contact') {
      setFirstText('')
      setSecondText(t("Əlaqə"))
      }
      else if(params.pathname === '/blogs') {
        setFirstText('')
        setSecondText(t("Bloqlar"))
        }
    else if(params.pathname === '/quruyol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Quru yolu ilə yük daşımaları"))
      setBgImage(Quru)
    }
    else if(params.pathname === '/havayol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
      setBgImage(Hava)
    }
    else if(params.pathname === '/demiryol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
      setBgImage(Demir)
    }
    else if(params.pathname === '/denizyol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
      setBgImage(Deniz)
    }
    else if(params.pathname === '/anbar-xidmeti') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Anbar xidməti"))
      setBgImage(Anbar)
    }
    else if(params.pathname === '/multimodal') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Multimodal daşımalar"))
      setBgImage(Multimodal)
    }
    else if(params.pathname === '/kompleks-logistika') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Kompleks logistika"))
      setBgImage(KompleksLogistika)
    }
    else if(params.pathname === '/gomruk-resmi') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Gömrük rəsmiləşdirilməsi"))
      setBgImage(Gomruk)
    }
    else if(params.pathname === '/topdan-satis') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Topdan satış"))
    }
  }, [])
  

  
  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };
  
  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };
  
  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };
  
  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  

  return (
    <>
    <nav className="flex items-center justify-between p-6 text-[#294E94] shadow-md font-titleFont ">
      <div className="flex items-center space-x-4">
        <a href="/">
          <img
            width={150}
            height={60}
            src={logo}
            alt="Logo"
            className="w-24 h-auto md:w-36 md:h-auto"
          />
        </a>
        <div className="hidden 2xl:flex items-center space-x-4 ">
          {/* Hizmetler butonları */}
          <div 
            className="relative flex items-center"
            onMouseEnter={openServicesMenu}
            onMouseLeave={closeServicesMenu}
          >
            <button 
              className="text-[#086170] font-bold focus:outline-none text-[15px]"
              onClick={(e) => e.preventDefault()}
            >
              {t("Logistika xidmətləri")}
            </button>
            <button
              onClick={() => setIsServicesOpen(!isServicesOpen)}
              className="ml-2 focus:outline-none text-[15px]"
            >
              <i className="fa-solid fa-caret-down text-[#086170]"></i> 
            </button>
            {isServicesOpen && (
              <div 
                className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-20 w-48"
                onMouseEnter={openServicesMenu}
                onMouseLeave={closeServicesMenu}
              >
                <a href="/quruyol" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Quru yük daşımalar")}</a>
                <a href="/havayol" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Hava nəqliyyatı")}</a>
                <a href="/demiryol" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Dəmir yolu nəqliyyatı")}</a>
                <a href="/denizyol" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Dəniz nəqliyyatı")}</a>
                <a href="/beynelxalq-ticaret" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Beynəlxalq ticarət əlaqələri uzre konsultasiya")}</a>
              </div>
            )}
          </div>
          <div 
            className="relative flex items-center"
            onMouseEnter={openProductsMenu}
            onMouseLeave={closeProductsMenu}
          >
            <button 
              className="text-[#086170] font-bold focus:outline-none text-[15px]"
              onClick={(e) => e.preventDefault()}
            >
              {t("Gömrük rəsmiləşdirilməsi xidmətləri")}
            </button>
            <button
              onClick={() => setIsProductsOpen(!isProductsOpen)}
              className="ml-2 focus:outline-none"
            >
              <i className="fa-solid fa-caret-down text-[#086170]"></i> 
            </button>
            {isProductsOpen && (
              <div 
                className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-50 w-48"
                onMouseEnter={openProductsMenu}
                onMouseLeave={closeProductsMenu}
              >
                <a href="/beyannamelerin-tertibi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bəyannamələrin tərtibi")} </a>
                <a href="/gomruk-xercleri" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Gömrük xərclərinin öncədən təyini")}</a>
                <a href="/resmilesdirme-icaze" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Rəsmiləşdirmə üzrə icazə sənədlərinin alınması")}</a>
                <a href="/merkezi-gomruk" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Mərkəzi gömrük ekspertizasına müraciət")}</a>
                <a href="/istenilen-gomruk" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("İstənilən gömrük idarələrində rəsmiləşmə")}</a>
                <a href="/odenissiz-konsultasiya" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Gömrük rəsmiləşdirmələri üzrə ödənişsiz konsultasiya")}</a>
              </div>
            )}
          </div>
          <a href="/anbar-xidmeti">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Anbar xidməti")}
            </button>
          </a>
          <a href="/multimodal">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Multimodal daşımalar")}
            </button>
          </a>
          <a href="/contact">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Əlaqə")}
            </button>
          </a>
          <a href="/blogs">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Bloqlar")}
            </button>
          </a>
        </div>
      </div>
      <div className="flex items-center space-x-4">   
        {/* Hizmetler butonu - Sadece büyük ekranlarda */}
        <a href="/xidmetler">
          <button className="bg-teal-500 text-white font-medium px-8 py-2 rounded-full flex items-center hidden md:flex">
            {t("Xidmətlərimiz")}
            <span className="ml-2">→</span>
          </button>
        </a>
        {/* Dil Seçimi - Sadece büyük ekranlarda */}
        <div className="relative inline-block text-left ml-36 hidden md:block">
          <button
            onClick={toggleDropdown}
            className="flex items-center space-x-2 px-4 py-2 border rounded-full border-teal-500"
          >
            <span>
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-6 w-5"
              />
            </span>
            <span className='text-[#294E94]'>{selectedLanguage.label}</span>
          </button>
          {isOpen && (
            <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {languageOptions.map((language) => (
                  <button
                    key={language.value}
                    onClick={() => handleLanguageChange(language)}
                    className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-gray-100"
                  >
                    <img 
                      src={language.flag}
                      alt={`${language.label} Flag`}
                      className="h-6 w-5"
                    />
                    <span>{language.label}</span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <button onClick={togglePanel} className="px-4 py-2 bg-teal-500 text-white border rounded-full">
          <svg
            className="w-6 h-6"
            fill="none" 
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button> 
      </div>
    </nav>
    {/* Mobil Menu */}
    <div
      className={`fixed top-0 right-0 z-50 flex flex-col items-center justify-between bg-[#124364] text-white w-full md:w-1/3 lg:w-1/4 h-full transform transition-transform duration-300 ${
        isPanelOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <button
        className="absolute top-4 right-4 text-white"
        onClick={togglePanel}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
      <nav className="flex flex-col space-y-4 mt-20 text-center w-full">
        <a href="/" className="text-xl hover:bg-teal-400 block w-full p-2">Trade Group</a>
        <a href="/about" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Haqqımızda")}</a>
        <div onClick={toggleServices} className="relative w-full">
          <a href="#" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Xidmətlərimiz")}  {isServicesExpanded ? "-" : "+"}</a>
          {isServicesExpanded && (
            <div className="flex flex-col mt-2 space-y-2">
              <a href="/quruyol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Quru yük daşımalar")}</a>
              <a href="/havayol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Hava nəqliyyatı")}</a>
              <a href="/demiryol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Dəmir yolu nəqliyyatı")}</a>
              <a href="/denizyol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Dəniz nəqliyyatı")}</a>
              <a href="/anbar-xidmeti" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Anbar xidməti")}</a>
              <a href="/gomruk-resmi" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Gömrük rəsmiləşdirməsi")}</a>
              <a href="/multimodal" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Multimodal daşımalar")}</a>
              <a href="/topdan-satis" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Topdan satış")}</a>
            </div>
          )}
        </div>
        <a href="/contact" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Əlaqə")}</a>
        <a href="/blogs" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Bloqlar")}</a>
        <div className="mt-6">
          <p className="text-lg">{t("Bizə təklifiniz var?")}</p>
          <a href="/contact">
            <button className="mt-2 px-8 py-2 bg-teal-500 text-white rounded-full">
              {t("Bizə yazın")}
            </button>
          </a>
        </div>
        <div className="flex space-x-4 mt-6 mx-auto">
          <a href="#"><i className="fab fa-facebook-f"></i></a>
          <a href="#"><i className="fab fa-instagram"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-linkedin-in"></i></a>
        </div>
      </nav>
      <p className="mt-6 mb-6">Narimanov metro st, Baku</p>
    </div>
  </>
  
  )
}

export default CommonHeader