import React, { useEffect, useState } from "react";
import { Anbar, azflag, contact, customHeader, Demir, Deniz, engflag, Gomruk, Hava, KompleksLogistika, multimodal, Multimodal, Quru, rusflag, warehouseHeader, wholesaleHeader } from '../../assets/index'
import { useLocation, useParams } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";


const languageOptions = [
  { value: "en", label: "English", flag: engflag },
  { value: "az", label: "Azərbaycan", flag: azflag },
  { value: "rus", label: "Russian", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation },
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const Header = () => {
  let params = useLocation()

  const { id } = useParams(); // Parametreyi al
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));
  const [bgImage, setBgImage] = useState(Quru)
  const [firstText, setFirstText] = useState('')
  const [secondText, setSecondText] = useState('')
  const [thirdText, setThirdText] = useState('')


  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

   
  useEffect(() => {
  if(params.pathname === '/about') {
  setFirstText('')
  setSecondText(t("Haqqımızda"))
  setBgImage(Multimodal)

  }
  else if(params.pathname === '/') {
    setFirstText('')
    setSecondText(t("Əsas"))
    }
    else if(params.pathname === '/xidmetler') {
      setFirstText('')
    setSecondText(t("Xidmətlərimiz"))
    setBgImage(multimodal)
      }
  else if(params.pathname === '/contact') {
    setFirstText('')
    setSecondText(t("Əlaqə"))
    setBgImage(contact)
    }
    else if(params.pathname === '/blogs') {
      setFirstText('')
      setSecondText(t("Bloqlar"))
      }
      else if(params.pathname === `/blogs/${id}`) {
        setFirstText('')
        setSecondText(t("Bloqlar"))
        }
  else if(params.pathname === '/quruyol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Quru yolu ilə yük daşımaları"))
    setThirdText(t("Logistika xidmətləri"))
    setBgImage(Quru)
  }
  else if(params.pathname === '/havayol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
    setThirdText(t("Logistika xidmətləri"))
    setBgImage(Hava)
  }
  else if(params.pathname === '/demiryol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
    setThirdText(t("Logistika xidmətləri"))
    setBgImage(Demir)
  }
  else if(params.pathname === '/denizyol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
    setThirdText(t("Logistika xidmətləri"))
    setBgImage(Deniz)
  }
  else if(params.pathname === '/anbar-xidmeti') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Anbar xidməti"))
    setBgImage(warehouseHeader)
  }
  else if(params.pathname === '/multimodal') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Multimodal daşımalar"))
    setBgImage(Multimodal)
  }
  else if(params.pathname === '/kompleks-logistika') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Kompleks logistika"))
    setBgImage(KompleksLogistika)
  }
  else if(params.pathname === '/gomruk-resmi') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Gömrük rəsmiləşdirilməsi"))
    setBgImage(customHeader)
  }
  else if(params.pathname === '/topdan-satis') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Topdan satış"))
    setBgImage(wholesaleHeader)
  }
  else if(params.pathname === '/beynelxalq-ticaret') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Beynəlxalq ticarət əlaqələri üzrə konsultasiya"))
    setThirdText(t("Logistika xidmətləri"))
    setBgImage(wholesaleHeader)
  }
  else if(params.pathname === '/beyannamelerin-tertibi') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Bəyannamələrin tərtibi"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/gomruk-xercleri') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Gömrük xərclərinin öncədən təyini"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/resmilesdirme-icaze') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Rəsmiləşdirmə üzrə icazə sənədlərinin alınması"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/merkezi-gomruk') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Mərkəzi gömrük ekspertizasına müraciət"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/istenilen-gomruk') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("İstənilən gömrük idarələrində rəsmiləşmə"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/odenissiz-konsultasiya') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Gömrük rəsmiləşdirmələri üzrə ödənişsiz konsultasiya"))
    setThirdText(t("Gömrük rəsmiləşdirməsi xidmətləri"))
  }
  else if(params.pathname === '/konsultasiya') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Ödənişsiz konsultasiya"))
  }
  
}, [])





  return (
    <>
  <div className="h-96 bg-blue-900 flex relative">
      <div 
        style={{ backgroundImage: `url(${bgImage})` }} 
        className="absolute top-0 left-0 w-full h-full bg-cover opacity-50"
      ></div>
      <div className="relative text-white p-6 w-5/6 mx-auto flex flex-col justify-around ">
        <nav className="text-base mb-6">
          <a href="/"><span>Trade Group</span></a>
          <span className="mx-2">|</span>
          <a href="/xidmetler"><span>{firstText}</span></a>
          {firstText ? <span className="mx-2">|</span> : ''}
          <a href="/xidmetler"><span>{thirdText}</span></a>
          {thirdText ? <span className="mx-2">|</span> : ''}
          <span className="text-teal-400 underline">{secondText}</span>
        </nav>
        <h1 className="text-4xl md:text-6xl font-bold mb-6 w-2/3">
          “{secondText}
        </h1>
        <a className="w-[200px]" href="/contact"> <button className="mt-6 px-4 py-2 bg-white text-[#124364] rounded-full hover:bg-teal-500 transition duration-300 ">
          {t("İndi müraciət et")}
          <span className="ml-2">→</span>
        </button></a>
      </div>
    </div>
  
       
</>
  );
};

export default Header;





























