import React, { useEffect, useRef, useState } from 'react'
import { Anbar, azflag, Demir, Deniz, engflag, flag, Gomruk, Hava, KompleksLogistika, logo, Multimodal, Quru, rusflag } from '../../assets/index'
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import { useLocation, useNavigate } from 'react-router-dom';


const languageOptions = [
    { value: "en", label: "English", flag: engflag },
    { value: "az", label: "Azərbaycan", flag: azflag },
    { value: "rus", label: "Russian", flag: rusflag },
  ];
  
  i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        az: { translation: azTranslation },
        rus: { translation: rusTranslation },
    },
    lng: localStorage.getItem("language") || "az",
    fallbackLng: "az",
    interpolation: { escapeValue: false },
  });

const CommonHeader = () => {

  let params = useLocation()
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));
    const [bgImage, setBgImage] = useState(Quru)
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isServicesExpanded, setIsServicesExpanded] = useState(false);
    const [firstText, setFirstText] = useState('')
    const [secondText, setSecondText] = useState('')
    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const productsTimeoutRef = useRef(null);
    const servicesTimeoutRef = useRef(null);
    const naviget = useNavigate();

    function logoutSubmit() {
      localStorage.setItem("login", "");
      localStorage.setItem("loginStatus", "Logged out successfully!");
      naviget("/");
    }
    const user = localStorage.getItem("user");

  
    useEffect(() => { 
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage) {
            const language = languageOptions.find(lang => lang.value === savedLanguage);
            if (language) {
                setSelectedLanguage(language);
                i18n.changeLanguage(language.value);
            }
        }
    }, []);
  
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
  
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        i18n.changeLanguage(language.value);
        localStorage.setItem("language", language.value);
        if(params.pathname === '/about') {
          setFirstText('')
          setSecondText(t("Haqqımızda"))
        
          }
          else if(params.pathname === '/') {
            setFirstText('')
            setSecondText(t("Əsas"))
            }
          else if(params.pathname === '/contact') {
            setFirstText('')
            setSecondText(t("Əlaqə"))
            }
            else if(params.pathname === '/blogs') {
              setFirstText('')
              setSecondText(t("Bloqlar"))
              }
            else if(params.pathname === '/xidmetler') {
              setFirstText('')
            setSecondText(t("Xidmətlərimiz"))
              }
          else if(params.pathname === '/quruyol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Quru yolu ilə yük daşımaları"))
            setBgImage(Quru)
          }
          else if(params.pathname === '/havayol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
            setBgImage(Hava)
          }
          else if(params.pathname === '/demiryol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
            setBgImage(Demir)
          }
          else if(params.pathname === '/denizyol') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
            setBgImage(Deniz)
          }
          else if(params.pathname === '/anbar-xidmeti') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Anbar xidməti"))
            setBgImage(Anbar)
          }
          else if(params.pathname === '/multimodal') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Multimodal daşımalar"))
            setBgImage(Multimodal)
          }
          else if(params.pathname === '/kompleks-logistika') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Kompleks logistika"))
            setBgImage(KompleksLogistika)
          }
          else if(params.pathname === '/gomruk-resmi') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Gömrük rəsmiləşdirilməsi"))
            setBgImage(Gomruk)
          }
          else if(params.pathname === '/topdan-satis') {
            setFirstText(t("Xidmətlər"))
            setSecondText(t("Topdan satış"))
          }
    };
  
    
    const togglePanel = () => {
      setIsPanelOpen(!isPanelOpen);
    };
  
    const toggleServices = () => {
      setIsServicesExpanded(!isServicesExpanded);
    };
  
  
  useEffect(() => {
    if(params.pathname === '/about') {
    setFirstText('')
    setSecondText(t("Haqqımızda"))
  
    }
    else if(params.pathname === '/') {
      setFirstText('')
      setSecondText(t("Əsas"))
      }
      else if(params.pathname === '/xidmetler') {
        setFirstText('')
      setSecondText(t("Xidmətlərimiz"))
        }
    else if(params.pathname === '/contact') {
      setFirstText('')
      setSecondText(t("Əlaqə"))
      }
      else if(params.pathname === '/blogs') {
        setFirstText('')
        setSecondText(t("Bloqlar"))
        }
    else if(params.pathname === '/quruyol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Quru yolu ilə yük daşımaları"))
      setBgImage(Quru)
    }
    else if(params.pathname === '/havayol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
      setBgImage(Hava)
    }
    else if(params.pathname === '/demiryol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
      setBgImage(Demir)
    }
    else if(params.pathname === '/denizyol') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
      setBgImage(Deniz)
    }
    else if(params.pathname === '/anbar-xidmeti') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Anbar xidməti"))
      setBgImage(Anbar)
    }
    else if(params.pathname === '/multimodal') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Multimodal daşımalar"))
      setBgImage(Multimodal)
    }
    else if(params.pathname === '/kompleks-logistika') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Kompleks logistika"))
      setBgImage(KompleksLogistika)
    }
    else if(params.pathname === '/gomruk-resmi') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Gömrük rəsmiləşdirilməsi"))
      setBgImage(Gomruk)
    }
    else if(params.pathname === '/topdan-satis') {
      setFirstText(t("Xidmətlər"))
      setSecondText(t("Topdan satış"))
    }
  }, [])
  

  
  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };
  
  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };
  
  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };
  
  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  

  return (
    <>
    <nav className="flex items-center justify-between p-6 text-[#294E94] shadow-md font-titleFont mt-12 ">
      <div className="flex items-center space-x-4">
        <a href="/">
          <img
            width={150}
            height={60} 
            src={logo}
            alt="Logo"
            className="w-24 h-auto md:w-36 md:h-auto"
          />
        </a>
        <div className="hidden 2xl:flex items-center space-x-4 ">
          {/* Hizmetler butonları */}
          <a href="/dashboard/contact">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Əlaqə")}
            </button>
          </a>
          <a href="/dashboard/konsultasiya">
            <button className="text-[#294E94] font-bold px-4 py-2 rounded-full flex items-center text-[15px]">
              {t("Konsultasiya")}
            </button>
          </a>
          <div className="d-flex">
          <button  
            type="submit"
            onClick={logoutSubmit} className=" bg-[#14B8A6] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full">Çıxış</button>
        </div>
        </div>
      </div>
    </nav>
  </>
  
  )
}

export default CommonHeader