import React, { useEffect } from "react";
import { multimodal } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { useState } from "react";
import axios from "axios";

const Blogs = () => {

  const [data, setData] = useState([]);

  function getData() {
    axios
      .get("https://www.tradegroup.az/tradegroup/blogs.php", { withCredentials: false })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Veri alınırken hata oluştu:", error);
      });
  }

  useEffect(() => {
    getData()
  }, []);
  
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              {data.map((blog) => (
                <a href={`/blogs/${blog.id}`} key={blog.id}>
                  <div className="bg-gray-100 p-4 rounded-md shadow-md">
                    <img src={`https://www.tradegroup.az/tradegroup/${blog.image}`} alt={blog.title} className="w-full h-48 object-cover rounded-md mb-4" />
                    <h3 className="text-lg font-semibold mb-2">{blog.title}</h3>
                    <p className="text-gray-700 text-sm">{blog.content.slice(0, 150)}...</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
